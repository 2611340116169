import { getISODay, getUnixTime } from "date-fns";
import compareMedications from "../medications/compareMedications";

export const activeTrays = ({ from, to, medication, dayTimeList, events }) => {
  if (medication) {
    events = events.filter((e) => compareMedications(e, medication));
  }

  const dataForInsert = events.map((e) => {
    const weekday = getISODay(e.date);
    const timeCategory = dayTimeList.find((f) => f.key === e.timeCategory);

    const caseNumber = weekday + timeCategory.index * 7;
    const result = {
      ...e,
      caseNumber,
    };

    return result;
  });

  return dataForInsert;
};

export function generateAlarms(events) {
  const alarms = [];
  events.forEach((entry) => {
    const alreadyExisting = alarms.find(
      (a) => entry.caseNumber === a[0] && getUnixTime(entry.date) === a[1]
    );
    if (alreadyExisting) return;
    if (entry.caseNumber)
      alarms.push([entry.caseNumber, getUnixTime(entry.date)]);
  });
  return alarms;
}

export function generateLedLight({ events, intensity = 100 }) {
  const alarms = [];
  events.forEach((entry) => {
    const alreadyExisting = alarms.find((a) => entry.caseNumber === a[0]);
    if (alreadyExisting) return;
    if (entry.caseNumber) alarms.push([entry.caseNumber, intensity]);
  });
  return alarms;
}
